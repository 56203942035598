/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
@include mat-core();
$mat-theme-ignore-duplication-warnings: true;
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// Primary Theme palettes
$md-primary: (50: #000000, 100: #b3e5fc, 200: #82d5fa, 300: #4fc4f7, 400: #2cb7f6, 500: #0478bd, // primary
  600: #099ce5, 700: #078ad1, 800: #0679BE, 900: #03589c, A100: #b3e5fc, A200: #82d5fa, A400: #4fc4f7, A700: #078ad1, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #ffffff, ));
// Alternative Dark Theme palettes
$md-darkteme: (50: #e0e0e0, 100: #b3b3b3, 200: #808080, 300: #4d4d4d, 400: #31727c, 500: #000000, 600: #000000, 700: #000000, 800: #000000, 900: #000000, A100: #a6a6a6, A200: #8c8c8c, A400: #737373, A700: #666666, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #ffffff, A700: #ffffff, ));
$md-accentgrey: (50: #e2f5f1, 100: #f0c6be, 200: #e6a093, 300: #db7a68, 400: #d45e47, 500: #cc4127, // primary
  600: #c73b23, 700: #c0321d, 800: #b92a17, 900: #ad1c0e, A100: #ffdedb, A200: #ffaea8, A400: #ff7f75, A700: #ff675c, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #ffffff, A700: #ffffff, ));
$md-mywarn: (50: #f6e3e4, 100: #e7b9bc, 200: #d88b90, 300: #c85d64, 400: #bc3a42, 500: #b01721, 600: #a9141d, 700: #a01118, 800: #970d14, 900: #87070b, A100: #ffb5b6, A200: #ff8284, A400: #ff4f52, A700: #ff3639, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #ffffff, ));
// Theme Defination
// Primary Theme
$themeBO-UI-primary: mat-palette($md-primary, 50);
$themeBO-UI-accent: mat-palette($md-primary, A200, A100, A400);
$themeBO-UI-warn: mat-palette($md-mywarn);
$themeBO-UI-theme: mat-light-theme($themeBO-UI-primary, $themeBO-UI-accent, $themeBO-UI-warn);
// Include theme styles for core and each component used in your app.
@include angular-material-theme($themeBO-UI-theme);
/* You can add global styles to this file, and also import other style files */

.width-30 {
  width: 30%
}

.width-20 {
  width: 20%
}

.width-40 {
  width: 40%
}

body {
  margin: 0px !important;
}

/* hide Scrollbar */
body {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/**Hide scrollbar end**/


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.page-header {
  flex: 0 0 auto;
  background-color: #dcdcdc;
}

.page-content {
  flex: 1 1 auto;
  position: relative;
  /* need this to position inner content */
  overflow-y: auto;
}

.page-footer {
  flex: 0 0 auto;
  height: auto;
  text-align: center;
  margin-bottom: -14px;
  // background-color: #dcdcdc;
}

.employeeProfile::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.employeeProfile {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* PAGE Title */
.page-title,
.page-title:active,
.page-title:link {
  font-weight: bold;
  text-transform: capitalize;
  text-decoration: none;
  font-variant: small-caps;
  color: var(--nav_list_active);
  font-size: 25px !important;
  text-align: center;
  margin-top: 20px;
}

// .page-title:hover {
//   color: brown
// }

.button {
  border-radius: 27px;
  width: 88px;
  height: 47px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  float: right;
  margin-right: 16px;
}

.add-icon {
  font-size: 60px;
  margin-left: 20px;
}

.mat-card {
  display: contents !important
}

.ck-editor__editable_inline {
  min-height: 142px !important;
}

/* mat-icon-button */

.close-button {
  float: right;
  top: -24px;
  right: -24px;
}

.close-icon {
  transition: 1s ease-in-out;
  padding: 0px !important;
}

.close-icon:hover {
  transform: rotate(180deg);
  background-color: #D32F2F;
  border-radius: 50%;
}

::ng-deep .icon-outside .close-button {
  float: right;
  top: -52px;
  right: -52px;
}

::ng-deep .icon-outside .mat-dialog-container {
  overflow: unset
}

.link {
  cursor: pointer;
  color: blue
}

/* Application header styles */
.app-toolbar {
  padding-top: 13px;
  color: var(--primary_text_color);
}

.img-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.app-menu-bar {
  margin-top: 10px;
  margin-right: 10px;
}

a.app-menu {
  text-decoration: none;
  color: var(--primary_text_color);
  font-size: 15px;
  margin: 5px 15px;
}

a.app-menu:hover,
a.app-menu:active {
  color: yellow;
}

.proj_name {
  padding-left: 11px;
  color: var(--primary_text_color);
  font-weight: bolder;
}

.layout-toolbar {
  height: 75px;
  padding: 10px;
  z-index: 3;
  position: fixed;
  background-color: var(--primary_color);

  .img {
    cursor: pointer;
    height: 60px;
    padding-top: 5px;
  }
}

.mat-drawer-container {
  background-color: white;
}

mat-icon {
  padding: 0px 5px;
}

.mat-drawer-content,
.mat-drawer-inner-container {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  /* Firefox */
}

.mat-drawer-content::-webkit-scrollbar,
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

.btn-close {
  background-color: white;
  border: none;
  font-weight: bold;
  float: right;
  cursor: pointer;
  outline-style: none !important;
  margin-top: 5px;
}

.mat-header-cell {
  justify-content: center !important;
}

mat-row,
mat-header-row,
mat-footer-row {
  cursor: pointer;
}

.highlight {
  background-color: yellow;
}

.norecord {
  text-align-last: center;
  margin-top: 10px;
}

.searchIcon {
  cursor: pointer;
}

.searchwidth {
  width: 300px;
}

.boldFont {
  font-weight: bold;
}

.mat-cell {
  padding-right: 5px;
}

.mat-header-cell {
  padding-right: 5px;
}

.center-alignment {
  place-content: center;
}

.mat-icon {
  width: auto !important;
}

.invalid-feedback {
  display: block!important;
  margin-top: 0rem;
  font-size: 80%;
  color: red;
}

.label-title {
  margin-left: 20px;
}

.mat-toolbar.mat-primary {
  background: var(--primary_color);
  color: var(--primary_text_color);
}

.left-sidenav {
  width:300px;
  background-color:rgb(205 236 241);
}

.cancel_but,
.submit_but {
  justify-content: center;
  font-weight: bolder;
}

.cancel_but {
  color: var(--secondary_text_color);
  background-color: var(--secondary_color)
}

.mat-pseudo-checkbox-checked {
  background: var(--button_color)!important;
}

.submit_but {
  color: var(--button_text_color);
  background-color: var(--button_color)
}

.form-check-input:checked {
  background-color: var(--primary_color)!important;
  border-color: var(--primary_color)!important;
}

.mat-calendar-body-selected {
  background-color: var(--primary_color) !important;
  color: var(--primary_text_color)!important;
}

.mat-input-element, .mat-input-element:disabled {
  color: black!important;
}

.footer {
  background-color: var(--footer_bgcolor);
  color: var(--footer_text_color);
}

.matWidth {
  z-index: 2 !important;
  width:60% !important;
  padding:0px 10px;
  max-height: 100%;
  position: fixed!important;
  transition: all 1s ease 0s;
  margin-top: 4.7%;
}
